
import { WlcmHomeApi } from '@/api/WlcmHomeApi';
import BotAgentForm from '@/components/bot/BotAgentForm.vue';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotAgentSettings, WlcmHomeCampaignSettings } from '@/types';
import isEqual from 'fast-deep-equal/es6';
import { CreateWlcmHomeCampaign } from 'ignite360-core';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'bot-agent-create-modal',
  components: { BotAgentForm },
})
export default class BotAgentCreateModal extends Vue {
  @Prop({ required: true, type: Boolean, default: false })
  readonly show!: boolean;


  isSubmitting = false;

  agentSettings: BotAgentSettings = this.resetValue();

  get settingsHaveChanged(): boolean {
    return !isEqual(this.agentSettings, this.resetValue());
  }

  async onSubmit() {
    this.isSubmitting = true;
    try {
      const formData = new FormData();
      
      // Add agent settings
      formData.append('name', this.agentSettings.name);
      formData.append('title', this.agentSettings.title);
      formData.append('phone', this.agentSettings.phone);
      formData.append('email', this.agentSettings.email);
      formData.append('botId', this.$route.params.id);

      // Add images if they exist
      const botAgentForm = this.$refs.botAgentForm as Vue & { pendingImageFile?: File, pendingSignatureFile?: File };
      if (botAgentForm?.pendingImageFile) {
        formData.append('agentImage', botAgentForm.pendingImageFile);
      }
      if (botAgentForm?.pendingSignatureFile) {
        formData.append('signatureImage', botAgentForm.pendingSignatureFile);
      }

      const agent = await WlcmHomeApi.createBotAgent(this.$route.params.id, this.$route.params.campaignId, formData);

      this.$notify.success('Successfully created agent');
      this.$emit('update:show', false);
      this.$emit('refresh', agent.data);
    } catch (e) {
      this.$notify.error({
        title: 'Creating an agent failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  resetValue(): BotAgentSettings {
    return {
      name: '',
      title: '',
      phone: '',
      email: '',
    };
  }

  @Watch('show')
  reset() {
    this.agentSettings = this.resetValue();
  }
}
