
// Add these imports at the top
import { S3_BUCKET, S3_REGION } from '@/api';
import { WlcmHomeApi } from '@/api/WlcmHomeApi';
import AppColorPicker from '@/components/ui/AppColorPicker.vue';
import { AuthMixin } from '@/mixins/AuthMixin';
import { BotAgentSettings } from '@/types';
import { getCompressedImage } from '@/utilities';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';



@Component({
  name: 'bot-agent-form',
  components: { AppColorPicker },
})
export default class BotAgentForm extends mixins(AuthMixin) {
  @Prop({ required: true, type: Object })
  readonly value!: BotAgentSettings;



  qrCodeDataUrl = '';
  imagePreview = '';
  pendingImageFile: File | null = null;
  signatureImagePreview = '';
  pendingSignatureFile: File | null = null;

  onInput(key: string, value: any) {
    const updatedValue: BotAgentSettings = JSON.parse(JSON.stringify(this.value));
    
    if (key.includes('.')) {
      const keys = key.split('.');
      let current: any = updatedValue;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!(keys[i] in current)) {
          current[keys[i]] = {};
        }
        current = current[keys[i]];
      }
      current[keys[keys.length - 1]] = value;
    } else {
      (updatedValue as any)[key] = value;
    }

    this.$emit('input', updatedValue);
  }



  async handleImagePreview(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (!file) return;

    this.pendingImageFile = file;
    this.onInput('imagePreview', URL.createObjectURL(file));
  }

  async uploadPendingImage() {
    if (!this.pendingImageFile) return;

    try {
      const compressedImage = await getCompressedImage(this.pendingImageFile);
      const formData = new FormData();
      formData.append('file', compressedImage);

      const response = await WlcmHomeApi.uploadAgentImage(
        this.$route.params.id,
        this.$route.params.campaignId,
        formData
      );

      this.onInput('imageId', response.data.imageId);
      this.imagePreview = '';
      this.pendingImageFile = null;
    } catch (error) {
      this.$notify.error({
        title: 'Upload failed',
        description: 'Failed to upload agent image',
      });
    }
  }

  async removeAgentImage() {
    if (this.value.imagePreview) {
      URL.revokeObjectURL(this.value.imagePreview);
      this.onInput('imagePreview', '');
      this.pendingImageFile = null;
      return;
    }

    try {
      await WlcmHomeApi.deleteAgentImage(
        this.$route.params.id,
        this.$route.params.campaignId
      );
      this.onInput('imageId', null);
    } catch (error) {
      this.$notify.error({
        title: 'Delete failed',
        description: 'Failed to delete agent image',
      });
    }
  }


  async handleSignatureImagePreview(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (!file) return;

    this.pendingSignatureFile = file;
    this.onInput('signatureImagePreview', URL.createObjectURL(file));
  }

  async uploadPendingSignatureImage() {
    if (!this.pendingSignatureFile) return;

    try {
      const compressedImage = await getCompressedImage(this.pendingSignatureFile);
      const formData = new FormData();
      formData.append('file', compressedImage);

      const response = await WlcmHomeApi.uploadAgentImage(
        this.$route.params.id,
        this.$route.params.campaignId,
        formData
      );

      this.onInput('signatureImageId', response.data.imageId);
      this.signatureImagePreview = '';
      this.pendingSignatureFile = null;
    } catch (error) {
      this.$notify.error({
        title: 'Upload failed',
        description: 'Failed to upload signature image',
      });
    }
  }

  async removeSignatureImage() {
    if (this.value.signatureImagePreview) {
      URL.revokeObjectURL(this.value.signatureImagePreview);
      this.onInput('signatureImagePreview', '');
      this.pendingSignatureFile = null;
      return;
    }

    try {
      await WlcmHomeApi.deleteAgentImage(
        this.$route.params.id,
        this.$route.params.campaignId
      );
      this.onInput('signatureImageId', null);
    } catch (error) {
      this.$notify.error({
        title: 'Delete failed',
        description: 'Failed to delete signature image',
      });
    }
  }
}
