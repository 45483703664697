
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AppColorPicker extends Vue {
  @Prop({ type: String, required: false })
  readonly value?: string;

  @Prop({ type: String, required: true })
  readonly id!: string;

  @Prop({ type: String, required: true })
  readonly label!: string;

  get currentValue(): string {
    return this.value || '#000000'; // Default to black if no value is provided
  }

  updateColor(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.$emit('input', target.value);
  }
}
